import { Grid } from "@mui/material";
import React from "react";
import {
  Create,
  Datagrid,
  DateInput,
  Edit,
  FunctionField,
  InfiniteList,
  Labeled,
  ListButton,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
  useRecordContext,
} from "react-admin";

const PersonalList = (props) => {
  return (
    <InfiniteList {...props}>
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <TextField source="id" />
        <FunctionField
          sortable={false}
          source="nombre"
          render={(record) => `${record.nombre}, ${record.apellido}`}
        />
        <TextField source="notas" />
        <TextField source="dni" />
        <FunctionField
          sortable={false}
          source="alias"
          render={(record) => record.alias || record.cbu}
        />
      </Datagrid>
    </InfiniteList>
  );
};

const PersonalForm = (props) => {
  const record = useRecordContext();
  return (
    <SimpleForm source={record} {...props}>
      <Grid
        container
        width="100%"
        columns={4}
        rowSpacing={1}
        columnSpacing={1}
        sx={{ maxWidth: "550px" }}
      >
        <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
          <Labeled>
            <FunctionField source="id" render={(record) => `#${record.id}`} />
          </Labeled>
        </Grid>
        <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
          <DateInput
            source="fechaIngreso"
            fullWidth
            parse={(dateString) => {
              if (!dateString) return null;
              const date = new Date(dateString);
              return new Date(
                date.getFullYear(),
                date.getMonth(),
                1
              ).toISOString();
            }}
          />
        </Grid>

        <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
          <TextInput source="nombre" />
        </Grid>
        <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
          <TextInput source="apellido" />
        </Grid>
        <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
          <TextInput source="dni" />
        </Grid>
        <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
          <TextInput source="cuil" />
        </Grid>
        <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
          <TextInput source="alias" />
        </Grid>
        <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
          <TextInput source="cbu" />
        </Grid>
        <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
          <TextInput source="email" />
        </Grid>
        <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
          <TextInput source="contacto" />
        </Grid>
        <Grid item xs={4} sx={{ maxHeight: "150px" }}>
          <TextInput
            source="notas"
            multiline
            fullWidth
            sx={{ minHeight: "140px", overflow: "auto", maxHeight: "140px" }}
          />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};
const PersonalEdit = (props) => {
  return (
    <Edit
      {...props}
      redirect={"list"}
      actions={
        <TopToolbar title="Personal">
          <ListButton label="Lista" />
        </TopToolbar>
      }
      mutationMode="pessimistic"
    >
      <PersonalForm />
    </Edit>
  );
};

const PersonalCreate = (props) => {
  return (
    <Create
      {...props}
      redirect={"list"}
      actions={
        <TopToolbar title="Personal">
          <ListButton label="Lista" />
        </TopToolbar>
      }
    >
      <PersonalForm />
    </Create>
  );
};

const Personal = {
  list: PersonalList,
  edit: PersonalEdit,
  create: PersonalCreate,
};

export default Personal;
