import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { parseISO } from "date-fns";
import { addDays } from "date-fns/fp";
import React, { useState } from "react";
import {
  BooleanField,
  BooleanInput,
  Button,
  ChipField,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  FunctionField,
  InfiniteList,
  Labeled,
  ListButton,
  NullableBooleanInput,
  NumberField,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  ReferenceOneField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  required,
  useRecordContext,
} from "react-admin";
import YearMonthDropdown from "../YearMonthDropdown";
import { supabase } from "../supabaseProvider";

const TiposPagoList = [
  { id: "1", name: "Mes Vencido" },
  { id: "2", name: "Mes Adelantado" },
];
const TipoFacturaList = [
  { id: "A", name: "A" },
  { id: "B", name: "B" },
  { id: "X", name: "X" },
  { id: "M", name: "M" },
];

const TiposFacturacionIva = [
  { id: "EX", name: "Excento 0%" },
  { id: "RI", name: "Responsable Inscripto 0%" },
  { id: "CF", name: "Consumidor Final 21%" },
  { id: "DC", name: "Prepaga 10,5%" },
];

const filters = [
  <NullableBooleanInput
    label="Estado"
    source="activo"
    nullLabel="Todos"
    falseLabel="Inactivos"
    trueLabel="Activos"
    alwaysOn={true}
  />,
];

const HuespedesList = (props) => {
  return (
    <InfiniteList {...props} filters={filters}>
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <TextField source="id" />
        <FunctionField
          sortable={false}
          source="nombre"
          render={(record) => `${record.nombre}, ${record.apellido}`}
        />
        <TextField source="dni" />
        <TextField source="obraSocial" />
        <ReferenceOneField
          reference="convenios"
          source="convenio"
          target="id_convenio"
          sort={{ field: "desde", order: "DESC" }}
          clickable
        >
          <ChipField source="nombre" clickable />
        </ReferenceOneField>
        <DateField source="fechaIngreso" />
        <DateField source="fechaEgreso" />
      </Datagrid>
    </InfiniteList>
  );
};

const CreateMovimientoButton = () => {
  const record = useRecordContext();
  return (
    <>
      <CreateButton
        label="Registrar Pago"
        resource="movimientos"
        state={{
          record: { id_huesped: record.id, tipo: 1 },
        }}
      />
    </>
  );
};

const PreparacionFacturaComponent = () => {
  const record = useRecordContext();
  const [preparacionFactura, setPreparacionFactura] = useState({
    message: "Elegir periodo a facturar",
    error: true,
  });

  const [open, setOpen] = React.useState(false);
  const [periodo, setPeriodo] = React.useState(null);

  const previsualizarFactura = async () => {
    try {
      const { data, error } = await supabase.rpc("getpreparacionfactura", {
        id_huespedp: record.id,
        periodop: periodo,
      });
      if (data && data.length && !error) setPreparacionFactura(data[0]);
      else setPreparacionFactura({ ...error, error: true });
    } catch (error) {
      setPreparacionFactura({ error: true, message: error });
    }
  };

  const generarFactura = async () => {
    try {
      const { data, error } = await supabase.rpc("generarfactura", {
        id_huespedp: record.id,
        periodop: periodo,
      });
      if (data && data.length && !error)
        setPreparacionFactura({ error: true, message: data });
      else setPreparacionFactura({ ...error, error: true });
    } catch (error) {
      setPreparacionFactura({ error: true, message: error });
    }
  };

  const closeDialog = () => {
    setPeriodo(null);
    setPreparacionFactura({
      message: "Elegir periodo a facturar",
      error: true,
    });
    setOpen(false);
  };

  const handleYearMonthChange = (selectedYear, selectedMonth) => {
    if (selectedYear && selectedMonth)
      setPeriodo(`${selectedYear}-${selectedMonth}-01`);
  };

  return (
    <>
      <Button color="success" variant="contained" onClick={() => setOpen(true)}>
        {"Preparar Factura"}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle id="alert-dialog-title"> Preparacion Factura</DialogTitle>
        <DialogContent>
          <Show actions={false}>
            <YearMonthDropdown onYearMonthChange={handleYearMonthChange} />
            <Button
              color="success"
              variant="contained"
              onClick={previsualizarFactura}
              sx={{ marginLeft: "20px" }}
              disabled={!periodo}
            >
              {"Previsualizar"}
            </Button>
            <Button
              color="warning"
              variant="contained"
              onClick={generarFactura}
              sx={{ marginLeft: "20px" }}
              disabled={
                !preparacionFactura ||
                !(preparacionFactura.factura_importe_final > 0)
              }
            >
              {"Generar Factura"}
            </Button>
            <SimpleShowLayout record={preparacionFactura}>
              <Grid container columns={4} rowSpacing={1} columnSpacing={1}>
                {preparacionFactura && !preparacionFactura.error ? (
                  <>
                    <Grid item xs={3}>
                      <Labeled>
                        <TextField source="nombre_huesped" />
                      </Labeled>
                    </Grid>
                    <Grid item xs={1}>
                      <Labeled>
                        <TextField source="tipo_pago" />
                      </Labeled>
                    </Grid>
                    <Grid item xs={1}>
                      <Labeled>
                        <TextField source="tipofactura" />
                      </Labeled>
                    </Grid>
                    <Grid item xs={1}>
                      <Labeled>
                        <DateField locales={"es-AR"} source="factura_desde" />
                      </Labeled>
                    </Grid>
                    <Grid item xs={2}>
                      <Labeled>
                        <DateField locales={"es-AR"} source="factura_hasta" />
                      </Labeled>
                    </Grid>
                    <Grid item xs={1}>
                      <Labeled>
                        <TextField source="obrasocial" />
                      </Labeled>
                    </Grid>
                    <Grid item xs={1}>
                      <Labeled>
                        <TextField source="factura_cuit" />
                      </Labeled>
                    </Grid>
                    <Grid item xs={1}>
                      <Labeled>
                        <NumberField
                          locales={"es-AR"}
                          source="factura_porcentaje_iva"
                        />
                      </Labeled>
                    </Grid>
                    <Grid item xs={3}>
                      <Labeled>
                        <NumberField
                          locales={"es-AR"}
                          source="factura_importe_final"
                        />
                      </Labeled>
                    </Grid>
                    <Grid item xs={4}>
                      <Labeled>
                        <TextField
                          multiline={true}
                          source="factura_descripcion"
                        />
                      </Labeled>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={4}>
                      <Typography variant="h6" align="center">
                        -- {preparacionFactura.message} --
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </SimpleShowLayout>
          </Show>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={closeDialog}>
            {"Cerrar"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const HuespedesForm = (props) => {
  const record = useRecordContext();
  return (
    <SimpleForm source={record} {...props}>
      <Grid
        container
        width="100%"
        columns={4}
        rowSpacing={1}
        columnSpacing={1}
        sx={{ maxWidth: "550px" }}
      >
        <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
          <TextInput source="nombre" validate={required()} />
        </Grid>
        <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
          <TextInput source="apellido" validate={required()} />
        </Grid>
        <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
          <TextInput source="dni" validate={required()} />
        </Grid>
        <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
          <SelectInput
            source="tipo_pago"
            validate={required()}
            choices={TiposPagoList}
          />
        </Grid>
        <Grid item xs={2} sx={{ maxWidth: "200px", maxHeight: "60px" }}>
          <ReferenceInput
            source="id_obraSocial"
            reference="obrasSociales"
            sort={{ field: "id", order: "ASC" }}
          >
            <SelectInput
              label="Obra Social"
              source="id_obraSocial"
              optionText="nombre"
              validate={required()}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
          <TextInput source="numeroAfiliado" />
        </Grid>
        <Grid item xs={4} sx={{ maxWidth: "200px", maxHeight: "60px" }}>
          <ReferenceInput
            source="convenio"
            reference="convenios_vencimiento"
            sort={{ field: "id", order: "ASC" }}
          >
            <SelectInput
              label="Convenio"
              source="id_convenio"
              optionText="nombre"
              validate={required()}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={2} sx={{ maxHeight: "60px" }}>
          <DateInput
            sx={{ maxWidth: "220px" }}
            source="fechaIngreso"
            validate={required()}
            fullWidth
            parse={(dateString) => {
              if (!dateString) return null;
              const date = new Date(dateString);
              return new Date(
                date.getFullYear(),
                date.getMonth(),
                1
              ).toISOString();
            }}
          />
        </Grid>
        <Grid item xs={2} sx={{ maxHeight: "60px" }}>
          <DateInput
            sx={{ maxWidth: "220px" }}
            source="fechaVencimientoConvenio"
            validate={required()}
            fullWidth
            parse={(dateString) => {
              if (!dateString) return null;
              const date = new Date(dateString);
              return new Date(
                date.getFullYear(),
                date.getMonth(),
                1
              ).toISOString();
            }}
          />
        </Grid>
        <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
          <SelectInput
            source="tipoFactura"
            validate={required()}
            choices={TipoFacturaList}
          />
        </Grid>
        <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
          <TextInput source="facturacionCuit" />
        </Grid>
        <Grid item xs={4} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
          <SelectInput
            source="facturacionIva"
            validate={required()}
            choices={TiposFacturacionIva}
          />
        </Grid>
        <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
          <BooleanInput source="activo" />
        </Grid>
        <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
          <DateInput
            source="fechaEgreso"
            fullWidth
            parse={(dateString) => {
              if (!dateString) return null;
              const date = new Date(dateString);
              return new Date(
                date.getFullYear(),
                date.getMonth(),
                1
              ).toISOString();
            }}
          />
        </Grid>
        <Grid item xs={4} sx={{ maxHeight: "150px" }}>
          <TextInput
            source="notas"
            multiline
            fullWidth
            sx={{
              minHeight: "140px",
              overflow: "auto",
              maxHeight: "140px",
            }}
          />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

const HuespedesEdit = (props) => {
  return (
    <Edit
      {...props}
      redirect={"list"}
      actions={
        <TopToolbar title="Huespedes">
          <ListButton label="Lista" />
        </TopToolbar>
      }
      mutationMode="pessimistic"
    >
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Datos">
          <HuespedesForm />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Facturas" path="facturas">
          <PreparacionFacturaComponent
            state={{
              record: { periodo: "2024-04-01" },
            }}
          />
          <InfiniteList {...props} actions={false}>
            <ReferenceManyField
              reference="facturas"
              target="id_huesped"
              label={false}
            >
              <Datagrid bulkActionButtons={false} rowClick="show">
                <DateField locales="es-AR" source="desde" />
                <TextField locales="es-AR" source="numero" />
                <TextField source="tipo" />
                <TextField source="cuit" />
                <NumberField locales="es-AR" source="importe" />
                <TextField multiline={true} source="descripcion" />
                <BooleanField source="cancelada" />
                <TextField locales="es-AR" source="fechaEnvio" />
                <FunctionField
                  label="Fecha Pago"
                  locales="es-AR"
                  render={(record) => {
                    const a = addDays(60, parseISO(record.fechaEnvio));
                    return a.toLocaleDateString("es-AR");
                  }}
                />
                <TextField source="notas" />
              </Datagrid>
            </ReferenceManyField>
          </InfiniteList>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Pagos" path="pagos">
          <CreateMovimientoButton />
          <InfiniteList {...props} actions={false}>
            <ReferenceManyField
              reference="movimientos"
              target="id_huesped"
              label={false}
            >
              <Datagrid bulkActionButtons={false} rowClick="show">
                <TextField source="id" />
                <DateField showTime={true} Field source="fechaMovimiento" />
                <ReferenceField reference="tiposMovimiento" source="tipo">
                  <TextField
                    source="nombre"
                    sx={{
                      fontVariant: "small-caps",
                      marginLeft: "4px",
                      fontWeight: "bold",
                    }}
                  />
                </ReferenceField>
                <TextField source="descripcion" />
                <NumberField source="importe" />
                <TextField source="user" />
              </Datagrid>
            </ReferenceManyField>
          </InfiniteList>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Edit>
  );
};
const HuespedesCreate = (props) => {
  return (
    <Create
      {...props}
      redirect={"list"}
      actions={
        <TopToolbar title="Huespedes">
          <ListButton label="Lista" />
        </TopToolbar>
      }
    >
      <HuespedesForm />
    </Create>
  );
};

const Huespedes = {
  list: HuespedesList,
  create: HuespedesCreate,
  edit: HuespedesEdit,
};

export default Huespedes;
