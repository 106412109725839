import { Grid, TextField } from "@mui/material";
import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  FunctionField,
  InfiniteList,
  Labeled,
  ListButton,
  NumberField,
  NumberInput,
  SimpleForm,
  TextInput,
  TopToolbar,
  required,
  useRecordContext,
} from "react-admin";

const ObraSocialList = (props) => {
  return (
    <InfiniteList storeKey={false} emptyWhileLoading {...props}>
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <FunctionField source="id" render={(record) => `#${record.id}`} />
        <FunctionField source="nombre" render={(record) => record.nombre} />
        <NumberField source="dias_pago" />
        <FunctionField source="notas" render={(record) => record.notas} />
      </Datagrid>
    </InfiniteList>
  );
};

const ObraSocialForm = (props) => {
  const record = useRecordContext();
  return (
    <SimpleForm source={record} {...props}>
      <Grid
        container
        width="100%"
        columns={4}
        rowSpacing={1}
        columnSpacing={1}
        sx={{ maxWidth: "550px" }}
      >
        <Grid item xs={4} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
          <Labeled>
            <FunctionField source="id" render={(record) => `#${record.id}`} />
          </Labeled>
        </Grid>
        <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
          <TextInput source="nombre" validate={required()} />
        </Grid>
        <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
          <NumberInput source="dias_pago" validate={required()} />
        </Grid>
        <Grid item xs={4} sx={{ maxHeight: "150px" }}>
          <TextInput
            source="notas"
            multiline
            fullWidth
            sx={{ minHeight: "140px", overflow: "auto", maxHeight: "140px" }}
          />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

const ObraSocialEdit = (props) => {
  return (
    <Edit
      {...props}
      redirect={"list"}
      mutationMode="pessimistic"
      actions={
        <TopToolbar title="Obra Social">
          <ListButton label="Lista" />
        </TopToolbar>
      }
    >
      <ObraSocialForm />
    </Edit>
  );
};

const ObraSocialCreate = (props) => {
  return (
    <Create
      {...props}
      redirect={"list"}
      actions={
        <TopToolbar title="Obras Social">
          <ListButton label="Lista" />
        </TopToolbar>
      }
    >
      <ObraSocialForm />
    </Create>
  );
};
const ObrasSociales = {
  list: ObraSocialList,
  create: ObraSocialCreate,
  edit: ObraSocialEdit,
};

export default ObrasSociales;
