import React from "react";
import { addDays } from "date-fns/fp";
import { parseISO, differenceInDays } from "date-fns";

import {
  Datagrid,
  TextField,
  FunctionField,
  DateInput,
  BooleanField,
  ReferenceField,
  InfiniteList,
  TopToolbar,
  ListButton,
  SimpleForm,
  NumberInput,
  TextInput,
  BooleanInput,
  Edit,
  WrapperField,
  NumberField,
  DateField,
  Filter,
  WithRecord,
  Labeled,
  PrevNextButtons,
} from "react-admin";
import { Grid, Stack } from "@mui/material";
import { Receipt } from "@mui/icons-material";

const DateFilter = (props) => (
  <Filter {...props}>
    <DateInput
      label="Desde"
      source="desde@gte"
      alwaysOn
      sx={{ maxWidth: "175px" }}
    />
    <DateInput
      label="Hasta"
      source="desde@lte"
      alwaysOn
      sx={{ maxWidth: "175px" }}
    />
  </Filter>
);

const FacturasList = (props) => {
  return (
    <InfiniteList
      {...props}
      filters={<DateFilter />}
      sort={{ field: "desde", order: "DESC" }}
      storeKey={false}
    >
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <DateField source="fechaMovimiento" locales={"es-AR"} />
        <FunctionField
          source="numero"
          label="Numero"
          render={(record) =>
            (record && record.link && (
              <>
                <TextField locales="es-AR" source="numero" />
                <Receipt
                  sx={{ verticalAlign: "middle", marginLeft: "4px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    window.open(record.link, "_blank");
                  }}
                />
              </>
            )) || <TextField locales="es-AR" source="numero" />
          }
        ></FunctionField>

        <ReferenceField reference="huespedes" source="id_huesped">
          <FunctionField
            source="id"
            render={(record) =>
              `#${record.id} ${record.nombre}, ${record.apellido}`
            }
          />
        </ReferenceField>
        <TextField source="tipo" />
        <WrapperField
          sortable={true}
          source="desde"
          label="Periodo"
          textAlign="right"
        >
          <Stack>
            <DateField source="desde" locales={"es-AR"} />
            <DateField source="hasta" locales={"es-AR"} />
          </Stack>
        </WrapperField>
        <TextField source="cuit" />
        <WrapperField
          sortable={true}
          source="importe"
          label="Importe"
          textAlign="right"
        >
          <Stack>
            <WithRecord
              render={(record) =>
                record.importe > record.importe_sin_iva ? (
                  <Labeled>
                    <NumberField
                      locales="es-AR"
                      source="importe"
                      label="C/IVA: "
                    />
                  </Labeled>
                ) : (
                  <></>
                )
              }
            />
            <NumberField locales="es-AR" source="importe_sin_iva" />
          </Stack>
        </WrapperField>
        <BooleanField source="cancelada" />
        <WrapperField
          sortable={true}
          source="fechaEnvio"
          label="Fecha Envio"
          textAlign="right"
        >
          <Stack>
            <DateField locales="es-AR" source="fechaEnvio" />
            <FunctionField
              label="Pago 60 dias"
              locales="es-AR"
              render={(record) => {
                if (!record.cancelada) {
                  const fechaProbablePago = addDays(
                    60,
                    parseISO(record.fechaEnvio)
                  );
                  const retraso = differenceInDays(
                    fechaProbablePago,
                    new Date()
                  );
                  return `${fechaProbablePago.toLocaleDateString(
                    "es-AR"
                  )}(${retraso})`;
                } else {
                  return null;
                }
              }}
            />
          </Stack>
        </WrapperField>
      </Datagrid>
    </InfiniteList>
  );
};

const FacturasEdit = (props) => {
  return (
    <Edit
      {...props}
      actions={
        <TopToolbar>
          <PrevNextButtons />
          <ListButton />
        </TopToolbar>
      }
      mutationMode="pessimistic"
    >
      <SimpleForm>
        <Grid
          container
          width="100%"
          columns={4}
          rowSpacing={1}
          columnSpacing={1}
          sx={{ maxWidth: "550px" }}
        >
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <Labeled>
              <DateField source="fechaMovimiento" locales="es-AR" />
            </Labeled>
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <Labeled>
              <NumberField source="id" />
            </Labeled>
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <Labeled>
              <ReferenceField reference="huespedes" source="id_huesped">
                <FunctionField
                  source="id"
                  render={(record) =>
                    `#${record.id} ${record.nombre}, ${record.apellido}`
                  }
                />
              </ReferenceField>
            </Labeled>
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <Labeled>
              <FunctionField
                label="Periodo"
                source="desde"
                render={(record) =>
                  `${parseISO(record.desde).toLocaleDateString(
                    "es-AR"
                  )} - ${parseISO(record.hasta).toLocaleDateString("es-AR")}`
                }
              />
            </Labeled>
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <WrapperField sortable={true} source="importe" textAlign="right">
              <Stack>
                <WithRecord
                  render={(record) =>
                    record.importe > record.importe_sin_iva ? (
                      <Labeled>
                        <NumberField
                          locales="es-AR"
                          source="importe"
                          label="Importe FINAL: "
                        />
                      </Labeled>
                    ) : (
                      <Labeled>
                        <NumberField
                          locales="es-AR"
                          source="importe_sin_iva"
                          label="Importe FINAL: "
                        />
                      </Labeled>
                    )
                  }
                />
              </Stack>
            </WrapperField>
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <Labeled>
              <TextField source="cuit" />
            </Labeled>
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberInput source="importe_sin_iva" />
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberInput source="importe" />
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <Labeled>
              <TextField source="tipo" />
            </Labeled>
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <NumberInput source="numero" />
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <DateInput source="fechaEnvio" />
          </Grid>
          <Grid item xs={2} sx={{ maxWidth: "150px", maxHeight: "60px" }}>
            <BooleanInput source="cancelada" />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              source="descripcion"
              multiline
              fullWidth
              sx={{
                minHeight: "160px",
                overflow: "auto",
                maxHeight: "161px",
              }}
            />
          </Grid>
          <Grid item xs={4} sx={{ maxWidth: "100%" }}>
            <TextInput
              source="notas"
              multiline
              fullWidth
              sx={{
                minHeight: "160px",
                overflow: "auto",
                maxHeight: "161px",
              }}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

const Facturas = {
  list: FacturasList,
  edit: FacturasEdit,
};

export default Facturas;
